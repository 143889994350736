import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: window.sessionStorage.getItem('user'),
    fullWidth: document.body.clientWidth,
  },
  mutations: {
    login(state, payload) {
      state.user = payload;
    },
    login_session(state, payload) {
      state.user = payload;
      window.sessionStorage.setItem('user', payload);
    },

    logout(state, payload) {
      state.user = null;
      window.sessionStorage.removeItem('user');
    },
  },
  actions: {
    login(context, payload) {
      context.commit('login', payload);
    },
    login_session(context, payload) {
      context.commit('login_session', payload);
    },
    logout(context) {
      context.commit('logout');
    },
  },
  modules: {},
});
