var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"container top_box"},[_c('a',{staticClass:"logo",on:{"click":_vm.toIndex}},[_c('img',{attrs:{"src":require("@/assets/images/logo.png")}})]),_c('div',{staticClass:"title",on:{"click":_vm.toInov}},[_c('img',{attrs:{"src":require("@/assets/images/logo2.png")}})]),_c('div',{staticClass:"other"},[_vm._m(0),_c('div',{class:_vm.showSharebox ? 'share_box share_box_mob' : 'share_box'},[_c('a',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/Footer/Contactus')}}},[_c('img',{attrs:{"src":require("@/assets/images/icon1.png")}})]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('a',{ref:"wxbox",staticClass:"item wx",attrs:{"target":"_blank"},on:{"click":_vm.showQRCode}},[_c('img',{attrs:{"src":require("@/assets/images/icon7.png")}}),(_vm.showWX)?_c('img',{staticClass:"qrcode",attrs:{"src":require("@/assets/images/wechat.jpg")}}):_vm._e()]),_vm._m(6),_vm._m(7)]),_c('i',{staticClass:"el-icon-share share_box_btn",on:{"click":_vm.showShare}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lag"},[_c('a',[_vm._v("EN")]),_vm._v("/ "),_c('a',{attrs:{"href":"https://www.eciawards.org.cn/?lang=cn"}},[_vm._v("CN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"item",attrs:{"href":"https://www.facebook.com/ECI-Awards-105116141163770","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icon2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"item",attrs:{"href":"https://www.instagram.com/eciawards/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icon3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"item",attrs:{"href":"https://twitter.com/ECIAwards2021","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icon4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"item",attrs:{"href":"https://www.linkedin.com/company/eci-awards/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icon5.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"item",attrs:{"href":"https://www.youtube.com/channel/UCjCGLcsXuIkXZgSxxMCxWHg","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icon6.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"item",attrs:{"href":"https://www.tiktok.com/@eciawards?lang=en","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icon8.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"item",attrs:{"href":"https://weibo.com/u/5361837053","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icon9.png")}})])
}]

export { render, staticRenderFns }