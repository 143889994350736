<template>
  <div class="menu">
    <div :class="showMenu ? 'menu_box menu_box_mob' : 'menu_box'">
      <div class="container">
        <ul class="menu_list">
          <li
            :class="item.name == sub_menu.name ? 'act' : ''"
            v-for="(item, index) in menu_list"
            :key="index"
            @click="toPage(item.link)"
          >
            <el-dropdown placement="bottom">
              <div>{{ item.name }}</div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :class="
                    item2.link == sub_name && sub_name != '' ? 'sub_act' : ''
                  "
                  v-for="(item2, index) in item.son"
                  :key="index"
                >
                  <div @click="toPage(item2.link)">
                    {{ item2.name }}
                  </div></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </div>

    <i class="el-icon-s-operation menu_mob" @click="openMenu"></i>
  </div>
</template>

<script>
import { MENU } from '@/router/config.js';
export default {
  name: 'Menu',
  data() {
    return {
      routername: '', //当前路由名称
      menu_list: [], //父级菜单列表
      sub_menu: {}, //父级名称+子级列表
      sub_name: '', //二级菜单名称
      showMenu: this.$store.state.fullWidth > 750 ? false : true,
    };
  },
  created() {},
  methods: {
    openMenu() {
      this.showMenu = !this.showMenu;
    },
    getMenu() {
      this.menu_list = MENU;

      //获取子菜单
      this.sub_menu = {};
      this.sub_name = '';
      this.routername = this.$route.path;

      this.menu_list.forEach(item => {
        for (let i in item.son) {
          //先查子级是否有对应的link
          if (item.son[i].link == this.routername) {
            this.sub_menu = item;
            this.sub_name = item.son[i].link;
            break;
          } else {
            let sunzi = item.son[i].subson;

            //没有的话只能去孙子招了
            for (let j in sunzi) {
              if (sunzi[j].link == this.routername) {
                this.sub_menu = item;
                this.sub_name = item.son[i].link;
                break;
              }
            }
          }
        }
      });
    },
    toPage(path) {
      if (path) {
        this.$router.push({
          path,
        });
      } else {
        this.$message('Coming Soon');
      }
    },
  },
  watch: {
    $route() {
      this.getMenu();
    },
    '$store.state.fullWidth'() {
      if (this.$store.state.fullWidth > 750) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.menu_list {
  padding-left: 200px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  li {
    line-height: 50px;
    cursor: pointer;
    font-weight: bold;
  }

  li:hover {
    color: $blue;
  }
}
.menu_mob {
  display: none;
  .icon-bar {
    background-color: #888;
  }
}
.menu_box {
  background-color: $bg2;
  .act {
    border-bottom: 2px solid #595757;
  }
}

@media (max-width: 1000px) {
  .menu_list {
    padding: 0;
  }
}
@media (max-width: 750px) {
  .menu {
    height: 53px;
    position: relative;
    background-color: $bg2;
  }
  .menu_box {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 53px;
  }
  .menu_list {
    display: block;
  }
  .menu_mob {
    display: block;
    position: absolute;
    cursor: pointer;
    font-size: 30px;
    top: 10px;
    left: 10px;
  }
  .menu_box_mob {
    display: none;
  }
}
/****修改ui样式 */
.el-popper[x-placement^='bottom'] {
  margin-top: 2px;
}
.el-popper[x-placement^='bottom'] {
  :deep(.popper__arrow) {
    display: none;
  }
}

.el-dropdown-menu {
  .sub_act {
    color: $blue;
  }
  .el-dropdown-menu__item:hover {
    color: $blue;
  }
}
</style>
