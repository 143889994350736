import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const routes = [
  {
    path: '/',
    name: 'Index',

    component: () => import('../views/Index.vue'),
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/Inovation',
    name: 'Inovation',
    component: () => import('../views/Inovation.vue'),
  },
  {
    path: '/InovationDetail',
    name: 'InovationDetail',
    component: () => import('../views/InovationDetail.vue'),
  },
  {
    path: '/WhoWeAre',
    name: 'WhoWeAre',
    component: () => import('../views/WhoWeAre.vue'),
  },
  {
    path: '/World',
    name: 'World',
    component: () => import('../views/World.vue'),
  },
  {
    path: '/WorldDetail',
    name: 'WorldDetail',
    component: () => import('../views/WorldDetail.vue'),
  },
  {
    path: '/Calendar',
    name: 'Calendar',
    component: () => import('../views/Calendar.vue'),
  },
  {
    path: '/Forget',
    name: 'Forget',
    component: () => import('../views/Forget.vue'),
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/Awards/Introduction',
    name: 'AwardsIntroduction',
    component: () => import('../views/Awards/Introduction.vue'),
  },
  {
    path: '/Awards/Updates',
    name: 'AwardsUpdates',
    component: () => import('../views/Awards/Updates.vue'),
  },
  {
    path: '/Awards/Judges',
    name: 'AwardsJudges',
    component: () => import('../views/Awards/Judges.vue'),
  },
  {
    path: '/Awards/Entry',
    name: 'AwardsEntry',
    component: () => import('../views/Awards/Entry.vue'),
  },
  {
    path: '/Awards/Cases',
    name: 'AwardsCases',
    component: () => import('../views/Awards/Cases.vue'),
  },
  {
    path: '/Awards/PrizedWork',
    name: 'AwardsPrizedWork',
    component: () => import('../views/Awards/PrizedWork.vue'),
  },
  {
    path: '/Awards/UpdatesDetail',
    name: 'AwardsUpdatesDetail',
    component: () => import('../views/Awards/UpdatesDetail.vue'),
  },
  {
    path: '/Awards/JudgesDetail',
    name: 'AwardsJudgesDetail',
    component: () => import('../views/Awards/JudgesDetail.vue'),
  },
  {
    path: '/Awards/EntryDetail',
    name: 'AwardsEntryDetail',
    component: () => import('../views/Awards/EntryDetail.vue'),
  },
  {
    path: '/Awards/CasesDetail/:id',
    name: 'AwardsCasesDetail',
    component: () => import('../views/CasesDetail.vue'),
  },
  {
    path: '/Festival/Introduction',
    name: 'FestivalIntroduction',
    component: () => import('../views/Festival/Introduction.vue'),
  },
  {
    path: '/Festival/Topics',
    name: 'FestivalTopics',
    component: () => import('../views/Festival/Topics.vue'),
  },
  {
    path: '/Festival/TopicsDetail',
    name: 'FestivalTopicsDetail',
    component: () => import('../views/Festival/TopicsDetail.vue'),
  },
  {
    path: '/Festival/Updates',
    name: 'FestivalUpdates',
    component: () => import('../views/Festival/Updates.vue'),
  },
  {
    path: '/Festival/UpdatesDetail',
    name: 'FestivalUpdatesDetail',
    component: () => import('../views/Festival/UpdatesDetail.vue'),
  },
  {
    path: '/Festival/Speakers',
    name: 'FestivalSpeakers',
    component: () => import('../views/Festival/Speaks.vue'),
  },
  {
    path: '/Festival/SpeakersDetail',
    name: 'FestivalSpeakersDetail',
    component: () => import('../views/Festival/SpeakersDetail.vue'),
  },
  {
    path: '/Academy/Introduction',
    name: 'AcademyIntroduction',
    component: () => import('../views/Academy/Introduction.vue'),
  },
  {
    path: '/Academy/gcgap',
    name: 'GreatChinaGatewayAcceleratorProgram',
    component: () => import('../views/Academy/gcgap.vue'),
  },
  {
    path: '/Academy/gcgapDetail',
    name: 'GreatChinaGatewayAcceleratorProgramDetail',
    component: () => import('../views/Academy/gcgapDetail.vue'),
  },
  {
    path: '/Academy/MarTech',
    name: 'AcademyMarTech',
    component: () => import('../views/Academy/MarTech.vue'),
  },
  {
    path: '/Academy/MarTechDetail',
    name: 'AcademyMarTechDetail',
    component: () => import('../views/Academy/MarTechDetail.vue'),
  },
  {
    path: '/Join/Sponsorship',
    name: 'JoinSponsorship',
    component: () => import('../views/Join/Sponsorship.vue'),
  },
  {
    path: '/Join/Partners',
    name: 'JoinPartners',
    component: () => import('../views/Join/Partners.vue'),
  },
  {
    path: '/NewsMedia/News',
    name: 'News',
    component: () => import('../views/NewsMedia/News.vue'),
  },
  {
    path: '/NewsMedia/NewsDetail',
    name: 'NewsDetail',
    component: () => import('../views/NewsMedia/NewsDetail.vue'),
  },
  {
    path: '/Footer/Support',
    name: 'FooterSupport',
    component: () => import('../views/Footer/Support.vue'),
  },
  {
    path: '/Footer/SupportDetail',
    name: 'FooterSupportDetail',
    component: () => import('../views/Footer/SupportDetail.vue'),
  },
  {
    path: '/Footer/Contactus',
    name: 'FooterContactus',
    component: () => import('../views/Footer/Contactus.vue'),
  },
  {
    path: '/Footer/Privatepolicy',
    name: 'FooterPrivatepolicy',
    component: () => import('../views/Footer/Privatepolicy.vue'),
  },
  {
    path: '/Footer/OurTeam',
    name: 'FooterOurTeam',
    component: () => import('../views/Footer/OurTeam.vue'),
  },
  {
    path: '/Footer/Cookie',
    name: 'FooterCookie',
    component: () => import('../views/Footer/Cookie.vue'),
  },
  {
    path: '/Footer/TermsConditions',
    name: 'FooterTermsConditions',
    component: () => import('../views/Footer/TermsConditions.vue'),
  },
  {
    path: '/Footer/Termofuse',
    name: 'FooterTermofuse',
    component: () => import('../views/Footer/Termofuse.vue'),
  },
  {
    path: '/Footer/Legalinformation',
    name: 'FooterLegalinformation',
    component: () => import('../views/Footer/Legalinformation.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
