<template>
  <div class="footer">
    <div class="container footer1">
      <ul class="flist">
        <li
          :class="item.name == sub_menu.name ? 'act' : ''"
          v-for="(item, index) in menu_list"
          :key="index"
          @click="toPage(item.link)"
        >
          <a>{{ item.name }}</a>
        </li>
      </ul>
      <div class="mail_box">
        <div class="title">Newsletter Subscription:</div>
        <input class="inp" placeholder="Country" />
        <input class="inp" placeholder="E-mail" />
        <button class="sub_btn" @click="mailSubmit">Submit</button>
      </div>
    </div>
    <div class="container">
      <div class="copyright">
        Copyright© ECI international 2022-2025
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >沪ICP备14051357号-1</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { FOOTER_MENU } from '@/router/config.js';
export default {
  name: 'Footer',
  data() {
    return {
      routername: '', //当前路由名称
      menu_list: [], //父级菜单列表
      sub_menu: {}, //父级名称+子级列表
      sub_name: '', //二级菜单名称
    };
  },
  created() {},
  methods: {
    getMenu() {
      this.menu_list = FOOTER_MENU;

      //获取子菜单
      this.sub_menu = {};
      this.sub_name = '';
      this.routername = this.$route.path;

      this.menu_list.forEach(item => {
        for (let i in item.son) {
          //先查子级是否有对应的link
          if (item.son[i].link == this.routername) {
            this.sub_menu = item;
            this.sub_name = item.son[i].link;
            break;
          } else {
            let sunzi = item.son[i].subson;

            //没有的话只能去孙子招了
            for (let j in sunzi) {
              if (sunzi[j].link == this.routername) {
                this.sub_menu = item;
                this.sub_name = item.son[i].link;
                break;
              }
            }
          }
        }
      });
      // console.log(this.sub_menu);
    },
    toPage(path) {
      if (path) {
        this.$router.push({
          path,
        });
      } else {
        this.$message('Coming Soon');
      }
    },
    mailSubmit() {
      this.$message('Coming Soon');
    },
  },
  watch: {
    $route() {
      this.getMenu();
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  font-weight: bold;
  min-height: 233px;
  background-color: $blue;
  color: #fff;
  .footer1 {
    display: flex;
    justify-content: space-between;
  }
  .flist {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 30px;
    flex-wrap: wrap;
    height: 160px;
    a {
      color: #fff;
      font-size: 16px;
      line-height: 30px;
      cursor: pointer;
    }
    a:hover {
      text-decoration: underline;
    }
    .act {
      text-decoration: underline;
    }
  }
  .copyright {
    text-align: center;
    color: #c9caca;
    margin-top: 30px;
    a {
      color: #c9caca;
      text-decoration: underline;
    }
  }
  .mail_box {
    float: right;
    width: 165px;
    margin-top: 30px;
    .title {
    }
    .inp {
      height: 24px;
      border: 1px solid #fff;
      background-color: transparent;
      margin-bottom: 5px;
      padding: 0 10px;
      width: 140px;
      line-height: 24px;
      color: #fff;
    }
    .inp::-webkit-input-placeholder {
      color: #fff;
    }
    .sub_btn {
      background-color: #fff;
      color: $blue;
      border: 0;
      text-align: center;
      width: 100px;
      float: right;
      line-height: 30px;
    }
  }
}

@media (max-width: 950px) {
  .footer {
    .flist {
      width: 100%;
      a {
        font-size: 12px;
      }
    }
  }
  .mail_box {
    display: none;
  }
}
</style>
