import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import store from './store';

//公共样式

import '@/assets/styles/base.scss';
const OSSURL = process.env.VUE_APP_OSS_URL;

Vue.config.productionTip = false;
Vue.prototype.$OSSURL = OSSURL;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
