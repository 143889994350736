<template>
  <div id="app">
    <div class="main_head">
      <Top></Top>
      <Header></Header>
      <Menu></Menu>
    </div>
    <router-view />
    <Footer></Footer>
  </div>
</template>
<script>
import Top from '@/components/Top.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Menu from '@/components/Menu.vue';
export default {
  name: 'App',
  components: {
    Header,
    Top,
    Footer,
    Menu,
  },
  mounted() {
    let that = this;

    window.onresize = function () {
      that.$store.state.fullWidth = document.body.clientWidth;
    };
  },
};
</script>
<style>
.main_head {
  position: fixed;
  width: 100%;
  z-index: 10;
}
</style>
