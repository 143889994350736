//主要导航
const MENU = [
  {
    name: 'Awards',
    link: '/Awards/Introduction',
    son: [
      {
        name: 'Introduction',
        link: '/Awards/Introduction',
      },
      {
        name: 'Updates',
        link: '/Awards/Updates',
        subson: [
          {
            name: 'AwardsUpdatesDetail',
            link: '/Awards/UpdatesDetail',
          },
        ],
      },
      {
        name: 'Judges',
        link: '/Awards/Judges',
        subson: [
          {
            name: 'AwardsJudgesDetail',
            link: '/Awards/JudgesDetail',
          },
        ],
      },
      {
        name: 'Entry',
        link: '/Awards/Entry',
        subson: [
          {
            name: 'AwardsEntryDetail',
            link: '/Awards/EntryDetail',
          },
        ],
      },
      {
        name: 'Winners',

        link: '/NewsMedia/NewsDetail?id=9',
        subson: [
          {
            name: 'AwardsWinnersDetail',
            link: '/Awards/WinnersDetail',
          },
        ],
      },
      {
        name: 'Cases',
        ///Awards/Cases
        link: '/Awards/Cases',
        subson: [
          {
            name: 'AwardsCasesDetail',
            link: '/Awards/CasesDetail',
          },
        ],
      },
    ],
  },
  {
    name: 'Festival',
    link: '/Festival/Introduction',
    son: [
      {
        name: 'Introduction',
        link: '/Festival/Introduction',
      },
      {
        name: 'Topics',
        link: '/Festival/Topics',
        subson: [
          {
            name: 'FestivalTopicsDetail',
            link: '/Festival/TopicsDetail',
          },
        ],
      },
      {
        name: 'Updates',
        link: '/Festival/Updates',
        subson: [
          {
            name: 'FestivalUpdatesDetail',
            link: '/Festival/UpdatesDetail',
          },
        ],
      },
      {
        name: 'Speakers',
        link: '/Festival/Speakers',
        subson: [
          {
            name: 'FestivalSpeakersDetail',
            link: '/Festival/SpeakersDetail',
          },
        ],
      },
    ],
  },
  {
    name: 'Academy',
    link: '/Academy/Introduction',
    son: [
      {
        name: 'Introduction',
        link: '/Academy/Introduction',
      },

      {
        name: 'Great China Gateway Accelerator Program',
        // link: '/Academy/gcgap',
        link: '',
        subson: [
          {
            name: 'GreatChinaGatewayAcceleratorProgramDetail',
            link: '/Academy/gcgapDetail',
          },
        ],
      },
      {
        name: 'Digital Marketing',
        link: '',
      },
      {
        name: 'Entrepreneurship & Innovation',
        // link: '/Academy/MarTech',
        link: '',
        subson: [
          {
            name: 'AcademyMarTechDetail',
            link: '/Academy/MarTechDetail',
          },
        ],
      },
    ],
  },
  {
    name: 'Why join us',
    link: '',
    son: [
      {
        name: 'Case Database',
        link: '/WhyJoinUs/CaseDatabase',
      },
      {
        name: 'Featured cases',
        link: '/WhyJoinUs/Featuredcases',
      },
      {
        name: 'Industry Reports',
        link: '/WhyJoinUs/IndustryReports',
      },
      {
        name: 'Innovator’s talk',
        link: '/WhyJoinUs/InnovatsTalk',
      },
      {
        name: 'Directory',
        link: '/WhyJoinUs/Directory',
      },
      {
        name: 'Referal Program',
        link: '/WhyJoinUs/ReferalProgram',
      },
      {
        name: 'Webinars',
        link: '/WhyJoinUs/Webinars',
      },
      {
        name: 'Monthly Galas',
        link: '/WhyJoinUs/MonthlyGalas',
      },
      {
        name: 'Incubation Roafshows',
        link: '/WhyJoinUs/IncubationRoafshows',
      },
    ],
  },
  {
    name: 'Join our Community',
    link: '/Join/Sponsorship',
    son: [
      {
        name: 'Membership',
        link: '',
      },
      {
        name: 'Sponsorship',
        link: '/Join/Sponsorship',
      },
      {
        name: 'Partners',
        link: '/Join/Partners',
      },
    ],
  },
  {
    name: 'News & Media',
    link: '/NewsMedia/News',
    son: [
      {
        name: 'Press Releases',
        link: '',
      },
      {
        name: 'News',
        link: '/NewsMedia/News',
        subson: [
          {
            name: 'NewsDetail',
            link: '/NewsMedia/NewsDetail',
          },
        ],
      },
      {
        name: 'Media Kit',
        link: '',
      },
      {
        name: 'High lights',
        link: '',
      },
    ],
  },
];
//底部导航
const FOOTER_MENU = [
  {
    name: 'Support',
    link: '/Footer/Support',
    son: [
      {
        name: 'Support',
        link: '/Footer/Support',
      },
      {
        name: 'SupportDetail',
        link: '/Footer/SupportDetail',
      },
    ],
  },
  {
    name: 'Careers',
    link: '',
  },
  {
    name: 'Contact us',
    link: '/Footer/Contactus',
    son: [
      {
        name: 'Contact us',
        link: '/Footer/Contactus',
      },
    ],
  },
  {
    name: 'Our Team',
    link: '/Footer/OurTeam',
    son: [
      {
        name: 'Our Team',
        link: '/Footer/OurTeam',
      },
    ],
  },
  {
    name: 'Private policy',
    link: '/Footer/Privatepolicy',
    son: [
      {
        name: 'Private policy',
        link: '/Footer/Privatepolicy',
      },
    ],
  },
  {
    name: 'Cookie policy',
    link: '/Footer/Cookie',
    son: [
      {
        name: 'Cookie policy',
        link: '/Footer/Cookie',
      },
    ],
  },
  {
    name: 'Terms & Conditions',
    link: '/Footer/TermsConditions',
    son: [
      {
        name: 'Terms & Conditions',
        link: '/Footer/TermsConditions',
      },
    ],
  },
  {
    name: 'Term of use',
    link: '/Footer/Termofuse',
    son: [
      {
        name: 'Term of use',
        link: '/Footer/Termofuse',
      },
    ],
  },
  {
    name: 'Legal information',
    link: '/Footer/Legalinformation',
    son: [
      {
        name: 'Legal information',
        link: '/Footer/Legalinformation',
      },
    ],
  },
  {
    name: 'Site map',
    link: '',
  },
  {
    name: 'Store',
    link: '',
  },
];
export { MENU, FOOTER_MENU };
