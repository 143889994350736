<template>
  <div class="top">
    <div class="container top_box">
      <a class="logo" @click="toIndex">
        <img src="@/assets/images/logo.png" />
      </a>
      <div class="title" @click="toInov">
        <img src="@/assets/images/logo2.png" />
      </div>
      <div class="other">
        <div class="lag">
          <a>EN</a>/
          <a href="https://www.eciawards.org.cn/?lang=cn">CN</a>
        </div>
        <div :class="showSharebox ? 'share_box share_box_mob' : 'share_box'">
          <a class="item" @click="$router.push('/Footer/Contactus')">
            <img src="@/assets/images/icon1.png" />
          </a>
          <a
            href="https://www.facebook.com/ECI-Awards-105116141163770"
            target="_blank"
            class="item"
          >
            <img src="@/assets/images/icon2.png" />
          </a>
          <a
            href="https://www.instagram.com/eciawards/"
            class="item"
            target="_blank"
          >
            <img src="@/assets/images/icon3.png" />
          </a>
          <a
            href="https://twitter.com/ECIAwards2021"
            class="item"
            target="_blank"
          >
            <img src="@/assets/images/icon4.png" />
          </a>
          <a
            href="https://www.linkedin.com/company/eci-awards/"
            class="item"
            target="_blank"
          >
            <img src="@/assets/images/icon5.png" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCjCGLcsXuIkXZgSxxMCxWHg"
            class="item"
            target="_blank"
          >
            <img src="@/assets/images/icon6.png" />
          </a>
          <a class="item wx" target="_blank" @click="showQRCode" ref="wxbox">
            <img src="@/assets/images/icon7.png" />
            <img
              class="qrcode"
              v-if="showWX"
              src="@/assets/images/wechat.jpg"
            />
          </a>
          <a
            href="https://www.tiktok.com/@eciawards?lang=en"
            class="item"
            target="_blank"
          >
            <img src="@/assets/images/icon8.png" />
          </a>
          <a href="https://weibo.com/u/5361837053" class="item" target="_blank">
            <img src="@/assets/images/icon9.png" />
          </a>
        </div>

        <i class="el-icon-share share_box_btn" @click="showShare"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Top',

  data() {
    return {
      showWX: false,
      showSharebox: this.$store.state.fullWidth > 1000 ? false : true,
    };
  },

  created() {
    //关闭微信二维码
    document.addEventListener('click', e => {
      if (!this.$refs.wxbox.contains(e.target)) {
        this.showWX = false;
      }
    });
  },
  methods: {
    toIndex() {
      this.$router.push({
        name: 'Index',
      });
    },
    toInov() {
      this.$router.push({
        name: 'Inovation',
      });
    },
    showQRCode() {
      this.showWX = !this.showWX;
    },
    showShare() {
      this.showSharebox = !this.showSharebox;
    },
  },
  watch: {
    '$store.state.fullWidth'() {
      if (this.$store.state.fullWidth > 1000) {
        this.showSharebox = false;
      } else {
        this.showSharebox = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.top {
  background-color: #fff;
  height: 51px;
  .top_box {
    position: relative;
  }
  .title {
    height: 51px;
    cursor: pointer;

    padding-left: 198px;

    float: left;
    img {
      width: 100%;
    }
  }
  .logo {
    position: absolute;
    left: 15px;
    top: 0px;
    cursor: pointer;
  }
  .other {
    float: right;
    padding-top: 14px;
  }
  .lag {
    float: left;
    line-height: 25px;
    margin-right: 26px;
  }
  .share_box {
    display: block;
    float: left;
    .item {
      cursor: pointer;
      display: block;
      float: left;
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
  .top_menu {
    float: right;
    position: relative;
    border-color: #ddd;
    padding: 9px 10px;
    margin-right: 0px;
    margin-top: -5px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    display: none;
    .icon-bar {
      background-color: #888;
    }
  }
  .wx {
    position: relative;
    cursor: pointer;
    .qrcode {
      position: absolute;
      width: 300px;
      height: 300px;
      display: block;
      z-index: 10;
      left: -150px;
    }
  }
  .share_box_btn {
    display: none;
  }
}
@media (max-width: 1000px) {
  .top {
    .logo {
      width: 60px;
      top: 0px;
      left: 10px;
      img {
        width: 100%;
      }
    }
    .top_menu {
      display: block;
    }
    .title {
      width: 170px;

      padding-left: 70px;
      img {
        margin-top: 10px;
      }
    }
    .lag {
      margin-right: 10px;
    }
    .share_box {
      // display: none;
      position: absolute;
      width: 30px;
      padding: 10px;
      right: 0;
      top: 50px;
      background-color: #fff;
      z-index: 20;
      .item {
        float: none;
        margin-bottom: 10px;
        margin-right: 0;
        width: 100%;
      }
    }
    .share_box_btn {
      display: block;
      float: right;
      top: 10px;
      font-size: 24px;
      cursor: pointer;
    }
    .share_box_mob {
      display: none;
    }
  }
}
</style>
