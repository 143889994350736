<template>
  <div class="header">
    <div class="container header_box">
      <div class="header_menu">
        <div
          :class="item.link == sub_menu.link ? 'act item' : 'item'"
          v-for="(item, index) in top_menu"
          :key="index"
          @click="toPage(item.link)"
        >
          {{ item.name }}
        </div>
      </div>
      <button v-if="!user" class="login_btn" @click="toLogin"></button>
      <div class="userbox" v-else>
        <span class="name">{{ user }}</span>
        <span class="logout" @click="logout">Logout</span>
      </div>
      <!-- <div class="search_box">
        <div class="search_btn">
          <img src="@/assets/images/search.png" />
        </div>
        <input value="" class="search_inp" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      sub_menu: {},
      //主要导航
      top_menu: [
        {
          name: 'Who we are',
          link: '/WhoWeAre',
        },
        {
          name: 'ECI World',
          link: '/World',
          son: [
            {
              name: 'WorldDetail',
              link: '/WorldDetail',
            },
          ],
        },
        {
          name: 'Calendar',
          link: '/Calendar',
        },
        {
          name: 'Donation',
          link: '',
        },
      ],
    };
  },
  created() {},
  methods: {
    getMenu() {
      this.sub_menu = {};
      this.top_menu.forEach((item, index) => {
        if (item.link == this.$route.path) {
          this.sub_menu = item;
        }
        //找子级的link
        if (item.son) {
          item.son.forEach(item2 => {
            if (item2.link == this.$route.path) {
              this.sub_menu = item;
            }
          });
        }
      });
    },
    toLogin() {
      // this.$router.push({
      //   name: 'Login',
      // });

      window.location.href =
        'https://www.eciawards.org.cn/index.php/Home/Login/index.html?lang=en';
    },
    toPage(link) {
      if (link) {
        this.$router.push({
          path: link,
        });
      } else {
        this.$message('Coming Soon');
      }
    },
    logout() {
      //登出
      this.$store.dispatch('logout').then(() => {
        this.$router.replace({
          name: 'Login',
        });
      });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    $route() {
      this.getMenu();
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  background-color: $blue;
  height: 50px;
  .header_box {
    overflow: hidden;

    height: 100%;
    .header_menu {
      float: left;
      margin-left: 200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      width: 45%;
      align-items: center;
      font-size: 14px;
      font-weight: bold;

      .item {
        color: #fff;
        cursor: pointer;
      }
      .item:hover {
        color: $bg2;
      }
      .act {
        color: $bg2;
      }
    }
  }
  .search_box {
    float: right;
    width: 310px;
    border: 1px solid #fff;
    height: 40px;
    margin-right: 10px;
    margin-top: 25px;
    .search_btn {
      float: left;
      margin-top: 5px;
      margin-left: 10px;
    }
    .search_inp {
      background-color: transparent;
      color: #fff;
      height: 40px;
      line-height: 40px;
      margin-left: 10px;
      width: 70%;
    }
  }
  .login_btn {
    background-image: url('../assets/images/loginBtn.png');
    background-repeat: no-repeat;
    background-color: transparent;
    cursor: pointer;
    width: 119px;
    padding: 0;

    border: 0;
    height: 35px;
    float: right;
    font-size: 16px;
    margin-right: 10px;
    margin-top: 7px;
  }
  .userbox {
    float: right;
    margin-top: 20px;
    .name {
      color: #fff;
    }
    .logout {
      margin-left: 10px;
      color: #ccc;
      cursor: pointer;
    }
  }
}
@media (max-width: 1000px) {
  .header {
    .header_box {
      .header_menu {
        font-size: 14px;
        margin-left: 0;
        .item {
          min-width: 80px;
        }
      }
    }
    .search_box {
      display: none;
    }
    .login_btn {
      width: 35px;
      border-radius: 35px;
      height: 35px;
      background-position: -75px;
    }
  }
}
</style>
